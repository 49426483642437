// App main
import 'unfonts.css';
import 'virtual:svg-icons-register';
import '@/css/app.pcss';
import '@/css/odometer.css';
import '@/css/hamburgers.scss';
import 'swiper/css';
import 'nouislider/dist/nouislider.min.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import mapStyle from './hemMap.json';

import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect';
import collapse from '@alpinejs/collapse';

window.Alpine = Alpine;
Alpine.plugin(collapse);
Alpine.plugin(intersect);

window["first"] = (array = []) => {
    if (!array) return null;
    return array[0] || null;
};

window["last"] = (array = []) => {
    if (!array) return null;
    return array[array?.length - 1] || null;
}

window["validateEmail"] = (email: string) => {
    if (!email) return false
    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

window["shallowEqual"] = (object1: any, object2: any) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (let key of keys1) {
        if (object1[key] !== object2[key]) {
            return false;
        }
    }
    return true;
}

window["isNumeric"] = (str: any) => {
    if (typeof str != "string") return false // we only process strings!
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}


const isUUID = (uuid = null) => {
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    return regexExp.test(uuid); // true
}

const currency = (number: any, end: boolean = true) => {
    number = isNaN(number) ? number.replace(/\s+/g, '') : number;
    return new Intl.NumberFormat('nb-NO').format(number) + (end ? ',-' : '');
}

const cfDecodeEmail = (encodedString: string) => {
    let email = "", r = parseInt(encodedString.substr(0, 2), 16), n, i;
    for (n = 2; encodedString.length - n; n += 2) {
        i = parseInt(encodedString.substr(n, 2), 16) ^ r;
        email += String.fromCharCode(i);
    }
    return email;
}

const developer = {
    get enabled(): Boolean {
        console.log('dev', document.body.classList.contains('dev'));
        return !!document.body.classList.contains('dev');
    }
}

const supportWebp = () => {
    const elem = document.createElement('canvas');
    if (!!(elem.getContext && elem.getContext('2d'))) {
        // was able or not to get WebP representation
        return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
    } else {
        return false;
    }
}


document.addEventListener('lazyloaded', (e: Event) => {
    const el = e.target as HTMLElement,
        parent = el.parentNode as HTMLElement;

    if (parent.classList.contains('rounded-full')) {
        setTimeout(() => {
            parent.classList.remove('bg-green-200')
        }, 300)
    }
});

const main = async () => {
    // Async load the vue module
    const {default: dayjs}: any = await import('dayjs');
    const {default: localizedFormat}: any = await import('dayjs/plugin/localizedFormat');
    const {default: relativeTime}: any = await import('dayjs/plugin/relativeTime');
    dayjs.extend(localizedFormat);
    dayjs.extend(relativeTime);
    await import('dayjs/locale/nb');
    dayjs.locale('nb');

    const {default: lottie} = await import('lottie-web/build/player/lottie_light.min');

    const {default: ScrollOut} = await import('scroll-out');

    const {default: scrollLock} = await import('./scroll-lock');

    await import('lazysizes/plugins/bgset/ls.bgset');
    await import('lazysizes/plugins/unveilhooks/ls.unveilhooks');
    await import('lazysizes');

    return {ScrollOut, dayjs, scrollLock, lottie};
};

// Execute async function
main().then(modules => {
    document.querySelectorAll(".__cf_email__").forEach((elm: HTMLElement) => {
        let parent: HTMLLinkElement = <HTMLLinkElement>elm.parentElement,
            email: string = cfDecodeEmail(elm.dataset.cfemail);
        if (parent) {
            parent.href = 'mailto:' + email;
            parent.innerText = email;
        }
    })

    window.lottie = modules.lottie;
    window.dayjs = modules.dayjs;
    window.ScrollOut = modules.ScrollOut;
    window.scrollLock = modules.scrollLock;
    window.app = {
        isUUID,
        currency,
        initOdomenter() {
            return {
                async init() {
                    await import('./odometer');
                    // @ts-ignore
                    new Odometer({
                        el: this.$root,
                        value: 0,
                        format: ' ddd',
                        duration: 3000,
                        theme: 'default',
                    });
                }
            }
        },
        edit(id) {
            return {
                lenke: null,
                id: id,
                init() {
                    this.hentEdit();
                    this.$watch('csrf', () => {
                        this.hentEdit();
                    });
                },
                async hentEdit() {
                    if (!this.csrf || this.user.isGuest || this.id == null) {
                        return false;
                    }

                    this.lenke = (await (await fetch(`/api/edit?ids=${this.id}`)).json()).data[0] || null;
                }
            }
        },
        initHeader() {
            return {
                open: false,
                showBg: false,
                small: false,
                init() {
                    modules.lottie.loadAnimation({
                        container: document.getElementById('logo'), // the dom element that will contain the animation
                        renderer: 'svg',
                        loop: false,
                        autoplay: false,
                        path: "/dist/logo-animasjon.json" // the path to the animation json
                    });

                    this.$nextTick(() => {
                        let menuOffset = 1;
                        const offetEl = document.querySelector('[data-menuoffset]');
                        if (offetEl) {
                            menuOffset = offetEl.firstElementChild.clientHeight - offetEl.lastElementChild.clientHeight - this.$el.clientHeight;
                        }
                        console.log(menuOffset)
                        modules.ScrollOut({
                            targets: "header",
                            offset: menuOffset,
                            onShown: () => {
                                // console.log(scrollLock.enabled);
                                this.showBg = true;
                                this.animateLogo(false);

                                /* Triggered when an element is shown */
                            },
                            onHidden: () => {
                                this.showBg = modules.scrollLock.enabled;
                                if (!modules.scrollLock.enabled) {
                                    this.animateLogo();
                                }
                                /* Triggered when an element is hidden */
                            }
                        });
                    });

                    this.$watch('open', (open) => {
                        console.log('open', open);
                        this.animateLogo(open);
                    });
                },
                animateLogo(stor = true) {
                    modules.lottie.setDirection(stor || this.open ? -1 : 1);
                    modules.lottie.play();
                },

            }
        },
        hentSession() {
            return {
                adminUrl: '/boss',
                showAdmin: localStorage.getItem('showAdmin') === 'true',
                visDebug: false,
                user: {
                    isAdmin: false,
                    isGuest: true
                },
                csrf: null,
                henterJobber: false,
                job: null,
                isIdle: false,
                queue: [],
                async init() {
                    try {
                        const json = (await (await fetch('/actions/users/session-info', {
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                            }
                        })).json())
                        this.user = json;
                        this.user.isAdmin = ['@mustasj.no'].find(item => json.email?.includes(item)) !== undefined
                        this.csrf = json.csrfTokenValue;
                    } catch (error) {
                        console.error('Error:', error);
                    }

                    if (!this.user.isGuest) {
                        await import('@alenaksu/json-viewer');
                        const {default: idle} = await import('idle-js');

                        try {
                            // @ts-ignore
                            Sentry.setUser({
                                username: this.user.username,
                                email: this.user.email
                            });
                        } catch (error) {
                            console.warn(error);
                        }

                        new idle({
                            idle: 5000, // idle time in ms
                            events: ['mousemove', 'keydown', 'mousedown', 'touchstart'], // events that will trigger the idle resetter
                            onIdle: () => {
                                this.isIdle = true;
                                // console.log('idle');
                            },
                            onActive: () => {
                                this.isIdle = false;
                                // console.log('active');
                            },
                            onHide: () => {
                                this.isIdle = true;
                                // console.log('hide');
                            },
                            onShow: () => {
                                this.isIdle = false;
                                // console.log('show');
                            }
                        }).start();
                        setInterval(() => {
                            if (!this.isIdle && this.showAdmin) {
                                this.hentJobb();
                            }
                        }, 3000);
                        this.adminbar();
                    }
                    this.hentJobb();

                    this.$watch('showAdmin', (show) => {
                        localStorage.setItem('showAdmin', show);
                        if (show) {
                            this.hentJobb();
                        }
                    })

                    this.$watch('visDebug', (active) => {
                        if (active) {
                            modules.scrollLock.enable();
                        } else {
                            modules.scrollLock.disable();
                        }
                    })
                },
                async hentJobb() {
                    if (!this.csrf || this.user.isGuest) {
                        return false;
                    }
                    this.henterJobber = true;
                    try {
                        console.log('henter jobb');
                        const json = (await (await fetch('/actions/queue/get-job-info', {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'X-CSRF-Token': this.csrf
                            },
                            body: JSON.stringify({
                                "limit": 50,
                                "dontExtendSession": 1
                            })
                        })).json());
                        if (this.estateId) {
                            this.job = json.jobs.filter((job) => {
                                return job.description.includes(this.estateId + ' fra Vitec');
                            })[0] || null;
                        }
                        this.henterJobber = false;
                        this.queue = json;
                    } catch (error) {
                        console.error('Error:', error);
                    }
                },
                async refreshCache(url: string = null) {
                    const refreshUrl = url || new URL(window.location.pathname, window.location);
                    console.log(window.location, refreshUrl.toString())
                    try {
                        await fetch('/actions/blitz/cache/refresh-urls', {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'X-CSRF-Token': this.csrf,
                                "Content-Type": "application/json",
                                'X-Requested-With': 'XMLHttpRequest'
                            },
                            body: JSON.stringify({
                                key: 'hme2qah7wjb-RXJ5weh',
                                urls: [refreshUrl.toString()]
                            })
                        });
                    } catch (e) {
                        console.error(e.message)
                    }
                },
                async adminbar() {
                    // @ts-ignore
                    const {default: adminbar}: any = await import('@/template/adminbar.html?raw');
                    var elem: HTMLDivElement = document.createElement('div');
                    elem.id = 'adminBar';
                    elem.innerHTML = adminbar;
                    document.body.appendChild(elem);
                }
            }
        },
        // TODO: flytt over til Hem da de bar cloudflare
        initCloudflare() {
            return {
                status: null,
                async getStatus(url: string = null) {
                    const refreshUrl = url || new URL(window.location.pathname, window.location);
                    const {headers} = await fetch(refreshUrl, {
                        method: 'HEAD',
                        headers: {
                            'Accept': 'application/json'
                        }
                    });
                    if (headers.get('Server') === 'cloudflare') {
                        this.status = headers.get('Cf-Cache-Status')
                    }
                }
            }
        },
        initCacheDetection() {
            return {
                hasCache: false,
                caches: [],
                async setup(checkHead: boolean = false) {
                    if (checkHead) {
                        const url = new URL(window.location.pathname, window.location.href);
                        const {headers} = await fetch(url, {method: 'HEAD', headers: {'Accept': 'application/json'}});
                        if (headers.get('Server') === 'cloudflare') {
                            this.caches.push({
                                label: 'Cloudflare',
                                status: headers.get('Cf-Cache-Status') || null,
                                time: headers.get('Last-Modified') || null
                            });
                        }
                    }

                    const isBlitz = (document.lastChild.nodeValue ?? '').includes('Blitz');
                    if (isBlitz) {
                        const blitzString = document.lastChild.nodeValue.toString().trim();
                        this.caches.push({
                            label: 'Blitz',
                            status: blitzString.includes('Cached by') ? 'HIT' : 'UNKNOWN',
                            time: blitzString.split(' ').pop()
                        });
                    }

                    this.hasCache = this.caches.length > 0;
                    // console.info('cache', this.hasCache, this.caches);
                },
                async refreshCache(url: string = null) {
                    const refreshUrl = url ? new URL(url) : new URL(window.location.pathname, window.location.href);
                    console.log(window.location, refreshUrl.toString())
                    try {
                        await fetch('/actions/blitz/cache/refresh-urls', {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'X-CSRF-Token': this.csrf,
                                "Content-Type": "application/json",
                                'X-Requested-With': 'XMLHttpRequest'
                            },
                            body: JSON.stringify({
                                key: 'hme2qah7wjb-RXJ5weh',
                                urls: [refreshUrl.toString()]
                            })
                        });
                    } catch (e) {
                        console.error(e.message)
                    }
                },
            }
        },
        initSok() {
            return {
                resultat: {
                    meglere: [],
                    oppdrag: [],
                    sider: []
                },
                visSok: false,
                visHelp: true,
                laster: false,
                watch() {
                    this.$watch('visSok', (vis) => {
                        if (vis) {
                            modules.scrollLock.enable();
                            if (this.sokQuery.length === 0) {
                                this.$nextTick(() => {
                                    this.$refs.sokFelt.focus();
                                });
                            }
                        } else {
                            modules.scrollLock.disable();
                            this.sokQuery = '';
                        }
                    });
                },
                async sok() {
                    let url = new URL(window.location.origin + '/api/sok');
                    const data: FormData = new FormData(this.$el),
                        query: any = Array.from(data.entries()).reduce((a, [val, key]) => {
                            a[val] = key;
                            return a;
                        }, {});

                    url.search = new URLSearchParams(query).toString();
                    this.visSok = true;

                    if (!query.q) {
                        this.visHelp = true;
                        this.resultat = {
                            meglere: [],
                            oppdrag: []
                        };
                        return;
                    }

                    this.laster = true;
                    try {
                        const {data} = (await (await fetch(url)).json())
                        this.resultat = data;
                        this.visHelp = false;
                        this.laster = false;
                    } catch (error) {
                        console.error('Error:', error);
                    }

                }
            }
        },
        initVariabler(autoRun = true) {
            return {
                variabler: [],
                init() {
                    // console.log('init variabler');
                    if (autoRun) {
                        this.hent();
                    }
                },
                async hent() {
                    this.variabler = (await (await fetch('/api/variabler')).json()).data || [];
                },
            }
        },
        initSlider(type = null, min = 0, max = 0) {
            return {
                min: min,
                max: max,
                slider: null,
                current: null,
                async make() {
                    let range = null;
                    const ranger = await import('nouislider');

                    switch (type) {
                        case 'penger':
                            range = {
                                'min': [this.min, 10000],
                                '20%': [1000000, 50000],
                                '70%': [10000000, 500000],
                                'max': [this.max]
                            }
                            break;
                        default:
                            range = {'min': [this.min, 1], 'max': [this.max, 1]}
                            break;
                    }

                    const start = this.$el.hasAttribute("data-single") ? [this.$el.dataset?.start || this.min] : [this.$el.dataset?.start || this.min, this.max];
                    this.slider = ranger.create(this.$el, {
                        start: start,
                        connect: start.length == 1 ? 'lower' : true,
                        range: range,
                    });

                    this.slider.on('update', (value) => {
                        if (value[0] == this.min && value[1] == this.max) {
                            this.current = null;
                        } else {
                            this.current = value;
                        }
                    });

                    /*this.slider.on('update', (value) => {
                        this.min = value[0];
                        this.max = value[1];
                    });*/
                }
            }
        },
        initPrisSlider() {
            return {
                min: 0,
                max: 0,
                slider: null,
                set(min = null, max = null) {
                    const setMin = Number((min ?? this.$refs.min.value).replace(/\s+/g, '')),
                        setMax = Number((max ?? this.$refs.max.value).replace(/\s+/g, ''));
                    console.log(setMin, setMax);
                    this.slider.set([setMin, setMax], true, true)
                },
                async make() {
                    const ranger = await import('nouislider');
                    this.$watch('kriterier.pris', (value) => {
                        if (value === null) {
                            this.slider.set([this.variabler.pris.min, this.variabler.pris.max])
                        }
                    });
                    let start = this.kriterier.pris,
                        fra = this.variabler.pris.min,
                        til = this.variabler.pris.max;

                    if (start) {
                        start = start[0].split(":");
                        fra = start[0];
                        til = start[1];
                    }
                    console.log('initial pris', fra, til)
                    this.slider = ranger.create(this.$el, {
                        start: [fra, til],
                        connect: true,
                        range: {
                            'min': [0, 10000],
                            '20%': [1000000, 50000],
                            '70%': [10000000, 500000],
                            'max': [this.variabler.pris.max]
                        },
                    });

                    this.slider.set([fra, til], true, true)

                    this.slider.on('set', (value) => {
                        console.log(value)
                        if (value[0] == this.variabler.pris.min && value[1] == this.variabler.pris.max) {
                            this.kriterier.pris = null;
                        } else {
                            this.kriterier.pris = value.map(item => parseInt(item)).join(':');
                        }
                    });

                    this.slider.on('update', (value) => {
                        this.min = +value[0];
                        this.max = +value[1];
                    });
                }
            }
        },
        initStorrelseSlider() {
            return {
                min: 0,
                max: 0,
                slider: null,
                set() {
                    this.slider.set([Number(this.$refs.min.value.replace(/\s+/g, '')), Number(this.$refs.max.value.replace(/\s+/g, ''))])
                },
                async make() {
                    const ranger = await import('nouislider');

                    this.$watch('kriterier.storrelse', (value) => {
                        if (value === null) {
                            this.slider.set([this.variabler.storrelse.min, this.variabler.storrelse.max])
                        }
                    });

                    let start = this.kriterier.storrelse,
                        fra = this.variabler.storrelse.min,
                        til = this.variabler.storrelse.max;

                    if (start) {
                        start = start[0].split(":");
                        fra = start[0];
                        til = start[1];
                    }

                    this.slider = ranger.create(this.$el, {
                        start: [fra, til],
                        connect: true,
                        step: 1,
                        range: {
                            'min': [0, 1],
                            '50%': [50, 10],
                            'max': [this.variabler.storrelse.max]
                        },
                    });

                    this.slider.on('set', (value) => {
                        if (value[0] == this.variabler.storrelse.min && value[1] == this.variabler.storrelse.max) {
                            this.kriterier.storrelse = null;
                        } else {
                            this.kriterier.storrelse = value.map(item => parseInt(item)).join(':');
                        }
                    });

                    this.slider.on('update', (value) => {
                        this.min = value[0];
                        this.max = value[1];
                    });
                }
            }
        },
        initSoveromSlider() {
            return {
                min: 0,
                max: 0,
                slider: null,
                set() {
                    this.slider.set([Number(this.$refs.min.value.replace(/\s+/g, '')), Number(this.$refs.max.value.replace(/\s+/g, ''))])
                },
                async make() {
                    const ranger = await import('nouislider');

                    this.$watch('kriterier.soverom', (value) => {
                        if (value === null) {
                            this.slider.set([this.variabler.soverom.min, this.variabler.soverom.max])
                        }
                    });

                    let start = this.kriterier.soverom,
                        fra = this.variabler.soverom.min,
                        til = this.variabler.soverom.max;

                    if (start) {
                        start = start[0].split(":");
                        fra = start[0];
                        til = start[1];
                    }

                    this.slider = ranger.create(this.$el, {
                        start: [fra, til],
                        connect: true,
                        step: 1,
                        range: {
                            'min': [0, 1],
                            'max': [this.variabler.soverom.max, 1]
                        },
                    });

                    this.slider.on('set', (value) => {
                        if (value[0] == this.variabler.soverom.min && value[1] == this.variabler.soverom.max) {
                            this.kriterier.soverom = null;
                        } else {
                            this.kriterier.soverom = value.map(item => parseInt(item)).join(':');
                        }
                    });

                    this.slider.on('update', (value) => {
                        this.min = value[0];
                        this.max = value[1];
                    });
                }
            }
        },
        initDefaultSkjema() {
            return {
                visSkjema: false,
                visSuccess: false,
                visErrors: false,
                visDebug: false,
                sender: false,
                params: {},
                errors: [],
                chechHash() {
                    console.log('check hash')
                    const hash = window.location.hash.substring(1) || null;
                    if (hash?.includes('popup-')) {

                        const type = hash.replace('popup-', '');
                        if (type === 'kontakt') {
                            setTimeout(() => {
                                document.getElementById('kontakt-knapp')?.click();
                                console.log(document.getElementById('kontakt-knapp'));
                            }, 500)
                        } else {
                            this.toggleSkjema({type: type})
                        }
                    }
                },
                async loadForm(handle: string = null) {
                    const response = await fetch(`/api/skjema/${handle}`);
                    this.$el.innerHTML = await response.text();
                },
                toggleSkjema(params = {}) {
                    this.params = params;
                    this.errors = [];
                    // this.fullskjerm = !this.visSkjema;
                    this.visSkjema = !this.visSkjema;
                    if (this.visSkjema) {
                        modules.scrollLock.enable();
                    } else {
                        modules.scrollLock.disable();
                        this.$nextTick(() => {
                            if (this.visSuccess) {
                                this.visSuccess = false;
                                this.visErrors = false;
                                this.errors = [];
                            }
                            this.visDebug = false;
                        })
                    }
                },
                async send(e) {
                    e.preventDefault();
                    this.sender = true;
                    this.errors = [];
                    const data = new FormData(this.$el);
                    const successMessage = this.$el.getAttribute('data-success-message') || null;
                    const action = this.$el.getAttribute('action') || '/';
                    // console.log(data.entries().filter((item) => {
                    //     console.log(item)
                    //     return true;
                    // }));
                    await fetch(action, {
                        method: 'POST',
                        headers: {
                            'Cache-Control': 'no-cache',
                            'Accept': 'application/json',
                            'X-Requested-With': 'XMLHttpRequest',
                            'HTTP_X_REQUESTED_WITH': 'XMLHttpRequest',
                            'X-CSRF-Token': this.csrf,
                        },
                        body: data
                    })
                        .then(res => res.json())
                        .then(json => {
                            console.log(json);
                            if (json.success) {
                                if (successMessage) {
                                    this.$refs.success.innerText = successMessage;
                                }
                                this.visSuccess = true;
                                try {
                                    // console.log(this.params.type);
                                    if (this.params.type) {
                                        // @ts-ignore
                                        window.dataLayer.push({
                                            'event': 'generate_leads',
                                            'record_leads_data': this.params.type
                                        });
                                    }
                                } catch (error) {
                                    console.warn(error);
                                }
                                if (this.params.type == 'login') {
                                    this.$refs.success.innerText = 'Laster siden på nytt';
                                    window.location.reload();
                                    return;
                                }
                            }

                            if (json.error) {
                                this.errors = [json.error.message || json.error];
                                this.visErrors = true;
                            } else if (json.errors) {
                                this.errors = json.errors;
                                this.visErrors = true;
                            } else {
                                this.visErrors = false;
                            }

                            if (json.debug || !this.user.isGuest) {
                                this.visDebug = true;
                                // this.$refs.payload.data = data.entries();
                                // this.$refs.payload.expandAll();
                                this.$refs.response.data = json;
                                this.$refs.response.expandAll();
                            }

                            if (json.returnUrl && json.onSuccess == 'redirect-return-url') {
                                window.location = json.returnUrl;
                            }

                        }).catch((error) => {
                            console.error('Error:', error);
                        }).then(() => {
                            this.sender = false;
                        });
                },
            }
        },
        hentOppdrag(currentEstateID, useHash: boolean = false) {
            return {
                oppdrag: [],
                meta: [],
                liste: false,
                laster: false,
                defaultKrit: null,
                placeholder: true,
                sortBy: [
                    {label: "Nyeste", value: "endret:asc"},
                    {label: "Eldste", value: "endret:desc"},
                    {label: "Størrelse", value: "bruksareal:asc"},
                    {label: "Pris", value: "pris:asc"},
                    {label: "Soverom", value: "soverom:asc"},
                    {label: "Alfabetisk", value: "overskrift:asc"}
                ],
                sort: null,
                kriterier: {
                    sok: null,
                    ids: [],
                    solgt: null,
                    prosjekt: null,
                    visninger: null,
                    bud: null,
                    megler: null,
                    pris: null,
                    storrelse: null,
                    soverom: null,
                    geo: null,
                    type: [],
                    source: null,
                    kommune: [],
                    omrade: [],
                    eieform: [],
                    limit: null,
                },
                init() {
                    this.setProperties(this.$root);
                    this.defaultKrit = {...this.kriterier};
                    if (useHash) {
                        this.checkHash();
                    }
                    this.$watch('kriterier', (kritterier) => {
                        // console.log(kritterier)
                        this.hent();
                    });
                    console.log('init')
                },
                initSort() {
                    this.$watch('sort', () => {
                        this.sorter();
                    });
                    this.sort = this.sortBy[0].value;
                },
                checkHash() {
                    let hash = decodeURIComponent(location.hash.substring(1));
                    let krit = null;
                    if (hash) {
                        krit = hash.split('&').reduce((cache, item) => {
                            let obj = item.split('='),
                                key = obj[0].toString(),
                                value = obj[1];

                            if (key === 'sok') {
                                value = value.split('+').join(' ');
                            }
                            let ny = {...cache};
                            ny[key] = value.split(',');
                            return ny;
                        }, {});
                    }

                    // console.log('checkhash', krit, this.kriterier);
                    const nyKritterier = {...this.defaultKrit, ...krit};
                    if (JSON.stringify(nyKritterier) !== JSON.stringify(this.kriterier)) {
                        console.log('Ikke lik', nyKritterier);
                        this.kriterier = nyKritterier;
                    }
                },
                reset() {
                    console.log('reset', this.defaultKrit);
                    this.kriterier = {...this.defaultKrit};
                },
                sorter(data: object = null) {
                    let nyData = data || this.oppdrag;
                    if (this.sort && !this.kriterier.geo) {
                        let textFields = ['overskrift'],
                            sortings = this.sort.split(':'),
                            field = sortings[0].toString(),
                            reverse = sortings[1] === 'desc';

                        // console.log(sortings);
                        if (textFields.includes(field)) {
                            nyData.sort((a, b) => {
                                return a[field].localeCompare(b[field], 'nb-NO');
                            })
                        } else if (field === 'endret') {
                            nyData.sort((a, b) => {
                                // @ts-ignore
                                return new Date(b.endret) - new Date(a.endret);
                            });
                        } else {
                            nyData.sort((a, b) => {
                                const at = Array.isArray(a[field]) ? a[field][0] : a[field],
                                    bt = Array.isArray(b[field]) ? b[field][0] : b[field];

                                if (at === null) {
                                    return 1;
                                }
                                if (at < bt) {
                                    return -1;
                                }
                                if (at > bt) {
                                    return 1;
                                }
                                return 0;
                            });
                        }
                        if (reverse) {
                            nyData = nyData.reverse();
                        }
                    }

                    this.oppdrag = nyData;
                },
                async hent() {
                    if (this.laster) {
                        return;
                    }
                    this.laster = true;
                    let url = new URL(window.location.origin + '/api/oppdrag'),
                        search = Object.entries(this.kriterier).reduce((a, [k, v]) => {
                            v = typeof v === 'boolean' ? +v : v;
                            // @ts-ignore
                            return (v === null || v.length === 0 ? a : (a[k] = v, a));
                        }, {});

                    url.search = new URLSearchParams({...search}).toString();
                    try {
                        let {data, meta} = (await (await fetch(url)).json());
                        this.meta = meta;
                        if (currentEstateID) {
                            data = data.filter((item) => {
                                return item.id !== currentEstateID;
                            });
                        }
                        this.placeholder = false;
                        this.sorter(data);
                    } catch (error) {
                        console.error(error);
                    }
                    if (useHash) {
                        delete search['prosjekt'];
                        let hash = new URLSearchParams({...search}).toString();
                        window.location.hash = hash.replace('?', '');
                    }
                    this.$nextTick(() => {
                        if (typeof this.swiper !== 'undefined') {
                            this.swiper.update();
                        }
                        if (typeof this.map !== 'undefined') {
                            this.updateMarkers();
                        }
                    });
                    this.laster = false;
                },
                setProperties($el) {
                    let kritt = [];
                    Array.from($el.getElementsByTagName("input")).forEach((element: any) => {
                        let attributeNode = element.getAttributeNode('x-model');
                        if (typeof (attributeNode) !== 'undefined' && attributeNode !== null) {
                            let propertyName = attributeNode.value.split(".")[1];
                            let propertyValue = element.value || element.defaultValue;

                            if (element.checked || element.selected || element.type == 'text' || element.type == 'hidden') {
                                kritt[propertyName] = propertyValue;
                            }
                        }
                    });
                    Object.assign(this.kriterier, kritt);
                }
            }
        },
        editOppdrag(estateId = null) {
            return {
                show: false,
                showImportStatus: false,
                sending: {
                    update: false,
                    slett: false,
                    status: true
                },
                oppdrag: null,
                bekreft: null,
                visBekreft: false,
                status: [],
                init() {
                    if (estateId) {
                        this.estateId = estateId;
                    }
                    this.oppdragJson(false);
                    this.sending.laster = false;
                    this.$watch('job', (value, oldValue) => {
                        if (value === null && typeof oldValue === 'object') {
                            this.oppdragJson(false);
                            setTimeout(() => {
                                window.location.reload();
                            }, 500);

                        }
                        // console.log(value, oldValue);
                    });
                },
                bekreftId() {
                    return this.bekreft == this.estateId;
                },
                async oppdragJson(visDebug = true) {
                    if (!this.estateId) {
                        return;
                    }
                    this.visDebug = false;
                    const response = (await (await fetch(`/api/oppdrag?id=${this.estateId}`)).json());
                    const oppdrag = response.data;
                    this.oppdrag = oppdrag;
                    if (response.meta.importStatus) {
                        this.status = response.meta.importStatus;
                        this.sending.status = false;
                    } else if (isUUID(this.estateId)) {
                        this.status = (await (await fetch(`/api/import-status?id=${this.estateId}`)).json()).data || [];
                        this.sending.status = false;
                    }
                    this.showImportStatus = !oppdrag;
                    const container = this.$refs.oppdragResponse;
                    container.data = oppdrag;
                    // container.expand('*');
                    this.visDebug = visDebug;
                },
                async finnJson(id) {
                    this.visDebug = false;
                    const container = this.$refs.oppdragResponse;
                    const response = await (await fetch(`/api/finn?format=json&id=${id}`)).json();
                    container.data = response.data || response;
                    container.expand('entry');
                    this.visDebug = true;
                },
                async prosjektJson() {
                    if (!this.estateId) {
                        return;
                    }
                    this.visDebug = false;
                    const container = this.$refs.oppdragResponse;
                    container.data = (await (await fetch(`/api/prosjekt?id=${this.estateId}`)).json()).data;
                    // container.expandAll();
                    this.visDebug = true;
                },
                /*async importStatus() {
                    if (!this.estateId) {
                        return;
                    }
                    this.status = (await (await fetch('/api/import-status?id=' + this.estateId)).json()).data || [];
                    this.sending.status = false;
                },*/
                async update(id = null) {
                    if (!this.estateId && !id) {
                        return;
                    }
                    this.sending.update = true;
                    await fetch('/actions/module/vitec/oppdater-oppdrag', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'X-CSRF-Token': this.csrf
                        },
                        body: JSON.stringify({
                            "id": id || this.estateId
                        })
                    })
                        .then(res => res.json())
                        .then(json => {
                            this.hentJobb();
                            // console.log(json);
                        }).catch((error) => {
                            console.error('Error:', error);
                        }).then(() => {
                            this.sending.update = false;
                        });
                },
                async slett() {
                    this.visBekreft = true;
                    if (!this.bekreftId()) {
                        return;
                    }
                    this.sending.slett = true;
                    await fetch('/actions/module/vitec/slett-oppdrag', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'X-CSRF-Token': this.csrf
                        },
                        body: JSON.stringify({
                            "id": this.estateId,
                            "bekreft": this.bekreft
                        })
                    })
                        .then(res => res.json())
                        .then(json => {
                            // console.log(json);
                            if (!json.error) {
                                window.location.reload();
                            }
                        }).catch((error) => {
                            console.error('Error:', error);
                        }).then(() => {
                            this.visBekreft = false;
                            this.sending.slett = false;
                        });
                }
            }
        },
        initMeglere(autoRun = true, params = null) {
            return {
                meglere: [],
                avdelinger: [],
                sortert: [],
                vis: false,
                async init() {
                    await this.hent();
                    if (autoRun) {
                        this.checkHash();
                        this.sokMegler(params);
                    }
                    this.vis = true;
                },
                checkHash() {
                    const hash = window.location.hash.substring(1) || null;
                    const felt = (<HTMLSelectElement>document.getElementsByName("avdeling")[0]) || null;
                    if (hash && felt) {
                        felt.value = hash;
                    }
                },
                sokMegler(obj = null) {
                    if (obj === null) {
                        const formEntries = new FormData(this.$refs.meglerForm);
                        obj = Array.from(formEntries.entries()).reduce((a, [val, key]) => {
                            a[val] = key;
                            return a;
                        }, {});
                    }

                    const query = obj.sok || null,
                        avdeling = obj.avdeling || null,
                        id = obj.id || null;

                    let data = this.meglere;
                    // console.log(query, avdeling, id);
                    if (query || avdeling || id) {
                        data = this.meglere.filter((megler) => {
                            // console.log(Object.values(megler));
                            const sokTreff = query ? [megler.navn.toLowerCase(), (megler.tittel || '').toLowerCase(), megler.epost, (megler.mob || '').replace(/\s/g, '')].find(element => element.includes(query.toLowerCase())) !== undefined : true;
                            // const sokTreff = query ? megler.navn.toLowerCase().indexOf(query.toLowerCase()) !== -1 : true;
                            const avdelingTreff = avdeling ? avdeling.split(',').some(avd => megler.avdId.includes(avd.toString())) : true;
                            const idTreff = id ? id.split(',').some(id => megler.WMids.includes(id.toString())) : true;
                            return sokTreff && avdelingTreff && idTreff;
                        })
                        if (id && id.split(',').length > 1) {
                            const ids = id.split(',');
                            data.sort((a, b) => {
                                return ids.indexOf(a.id.toString()) - ids.indexOf(b.id.toString());
                            });
                        }
                    }
                    data.map((megler) => {
                        megler.avdeling = this.avdelinger.filter((avd) => megler.avdId.includes(avd.id.toString()));
                        return megler;
                    });

                    this.sortert = data;
                },
                async hent() {
                    this.avdelinger = (await (await fetch('/api/avdeling')).json()).data;
                    this.meglere = (await (await fetch('/api/megler')).json()).data;
                }
            }
        },
        initSlideshow(type = null) {
            return {
                swiper: null,
                async setup(el = this.$el, options = {}) {
                    const {Autoplay, Keyboard, Navigation, Pagination, Swiper} = await import('swiper');
                    Swiper.use([Autoplay, Keyboard, Navigation, Pagination]);
                    console.log(`ìnit slideshow: ${type}`, el);
                    let baseOptions: object = {
                        pagination: {
                            el: '.pagination',
                            type: 'fraction'
                        },
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev'
                        }
                    }
                    if (type === 'oppdrag') {
                        baseOptions = Object.assign(baseOptions, {
                            slidesPerView: 1,
                            spaceBetween: 18,
                            breakpoints: {
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 24
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 24
                                }
                            }
                        });
                    } else if (type === 'sitat-kompakt') {
                        baseOptions = Object.assign(baseOptions, {
                            slidesPerView: 1,
                            spaceBetween: 18,
                            breakpoints: {
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 24
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 24
                                }
                            }
                        })
                    } else if (type === 'sitat-full') {
                        baseOptions = Object.assign(baseOptions, {
                            loop: true,
                            speed: 700,
                            slidesPerView: 1,
                            spaceBetween: 24,
                            breakpoints: {
                                800: {
                                    slidesPerView: 2,
                                    spaceBetween: 68,
                                }
                            },
                            centeredSlides: true,
                            autoplay: {
                                delay: 5000,
                                disableOnInteraction: false
                            },
                        });
                    } else {
                        baseOptions = Object.assign(baseOptions, {
                            keyboard: {
                                enabled: true
                            },
                        });
                    }
                    baseOptions = Object.assign(baseOptions, options)
                    this.swiper = new Swiper(el, baseOptions);
                }
            }
        },
        initSide() {
            return {
                view: 'vanlig',
                iframeUrl: null,
                showBud: false,
                fullImage: null,
                showFullscreen: false,
                megler: null,
                aktivtBud: null,
                start() {
                    this.checkHash();
                    this.$watch('fullImage', (value) => {
                        // console.log('fullImage', value);
                        if (value) {
                            modules.scrollLock.enable();
                        } else {
                            modules.scrollLock.disable();
                        }
                    })
                    this.$watch('iframeUrl', (value) => {
                        // console.log('iframeUrl', value);
                        if (value) {
                            modules.scrollLock.enable();
                        } else {
                            modules.scrollLock.disable();
                        }
                    })
                    this.$watch('showFullscreen', (value) => {
                        // console.log('showFullscreen', value);
                        if (value) {
                            modules.scrollLock.enable();
                        } else {
                            modules.scrollLock.disable();
                        }
                    })
                    this.$watch('sortert', (meglerer) => {
                        this.megler = meglerer[0] || null;
                    })
                },
                async checkBud() {
                    let bud = (await (await fetch(`/api/bud?id=${this.estateId}`)).json()).data || null;
                    this.aktivtBud = bud?.reverse()[0]?.sum || null;
                },
                checkAutoplay(iframeUrl) {
                    let url = new URL(iframeUrl);
                    if (iframeUrl.includes("player.vimeo.com") || iframeUrl.includes("youtube.com/embed")) {
                        url.searchParams.append('autoplay', '1');
                    } else if (iframeUrl.includes("materport")) {
                        url.searchParams.append('play', '1');
                    }
                    this.iframeUrl = url.toString();
                },
                karusell(index = 0) {
                    this.showFullscreen = true;
                    this.$nextTick(() => {
                        if (this.swiper.params?.loop) {
                            this.swiper.slideToLoop(index, 0);
                        } else {
                            this.swiper.slideTo(index, 0);
                        }
                        // console.log(index, this.swiper);
                    });
                },
                switchView(type) {
                    this.view = type;
                    if (type === 'bilder') {
                        window.scrollTo({top: 0});
                    }
                    location.hash = this.view === 'vanlig' ? '' : this.view;
                },
                toggleBud() {
                    this.showBud = !this.showBud;
                    if (this.showBud) {
                        modules.scrollLock.enable();
                    } else {
                        modules.scrollLock.disable();
                    }
                },
                checkHash() {
                    let hash = location.hash.substring(1);
                    if (hash && !hash?.includes('popup-')) {
                        this.view = hash;
                    } else {
                        this.view = 'vanlig';
                    }
                },
                async copyUrl(text = null) {
                    try {
                        let url = text || location.href,
                            hash = location.hash,
                            index_of_hash = url.indexOf(hash) || url.length,
                            hashless_url = url.substr(0, index_of_hash),
                            content = this.$el.innerHTML;

                        // console.log(hashless_url || url);
                        await navigator.clipboard.writeText(hashless_url || url);
                        this.$el.classList.add("bg-green", "!text-white");
                        this.$el.innerHTML = 'Kopiert!';
                        setTimeout(() => {
                            this.$el.classList.remove("bg-green", "!text-white");
                            this.$el.innerHTML = content;
                        }, 1000);
                        console.log('Page URL copied to clipboard');
                    } catch (err) {
                        console.error('Failed to copy: ', err);
                    }
                },
                lukk() {
                    this.showFullscreen = null;
                    this.iframeUrl = null;
                    this.fullImage = null;
                }
            }
        },
        initProsjekt(prosjekt) {
            return {
                enheter: [],
                sorterte: [],
                bud: [],
                visLedige: false,
                tomt: false,
                init() {
                    this.tomt = [8, 25].includes(prosjekt?.type.value);
                    // console.log(this.tomt);
                    this.enheter = prosjekt?.enheter;
                    this.bud = prosjekt?.enheter.filter(enhet => enhet.lenker.budUrl)
                    this.sorter();
                },
                status(enhet) {
                    if (enhet.status.toLowerCase() === 'reservert') {
                        return 'bg-yellow-400';
                    } else if (!enhet.solgt) {
                        return 'bg-green-400 text-green';
                    } else {
                        return 'bg-orange-200 text-orange';
                    }
                },
                sorter() {
                    let data = this.enheter;
                    if (this.visLedige) {
                        data = data.filter((item) => {
                            return !item.solgt;
                        });
                    }

                    this.sorterte = data;
                },
                showEnhet(index) {
                    let size = this.enheter[index].bilder[0].sizes.slice(-1) || null;
                    // console.log(sizes);
                    if (size) {
                        this.fullImage = size[0].url;
                    }
                }
            }
        },
        initMap(lat = 63.4346, lng = 10.3985, zoom = 15) {
            return {
                visKart: false,
                visListe: false,
                map: null,
                cluster: null,
                fullKart: false,
                googleLayer: null,
                avdelinger: [],
                geo: null,
                meMarker: null,
                disabled: !navigator.geolocation,
                laster: false,
                hoverId: null,
                async setupMap(type = null, options = {}) {
                    const {default: L} = await import('leaflet');

                    let kartSelector = 'kart',
                        defaultOptions = {
                            center: [lat, lng],
                            minZoom: 8,
                            zoom: zoom,
                            maxZoom: 20,
                            scrollWheelZoom: false,
                            zoomControl: false,
                            tap: false
                        };

                    if (window.innerWidth <= 768 && type === 'kjope') {
                        this.visListe = true;
                        kartSelector = 'mobKart';
                    }
                    if (type === 'kjope') {
                        defaultOptions.scrollWheelZoom = true;
                        if (this.kriterier?.geo) {
                            this.toggleNav();
                        }
                    }

                    this.map = L.map(this.$refs[kartSelector], {...defaultOptions, ...options});
                    /*L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png', {
                        subdomains: 'abcd',
                        maxZoom: 20,
                        minZoom: 1,
                    }).addTo(this.map);*/
                    await import('maplibre-gl/dist/maplibre-gl.css');
                    await import('maplibre-gl');
                    await import('@maplibre/maplibre-gl-leaflet');

                    L.maplibreGL({
                        style: mapStyle,
                        attribution: '<a href="https://openfreemap.org" target="_blank">OpenFreeMap</a> <a href="https://www.openmaptiles.org/" target="_blank">© OpenMapTiles</a> Data fra <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> '
                    }).addTo(this.map);

                    this.map.whenReady(() => {
                        setTimeout(() => {
                            this.visKart = true;
                            this.map.invalidateSize(false);
                        }, 200);
                    });

                    this.$watch('geo', coords => {
                        if (coords) {
                            if (typeof this.kriterier?.geo !== 'undefined') {
                                this.kriterier.geo = coords.latitude + ":" + coords.longitude + ":km";
                            }
                            const iconSize = 28;
                            const meIkon = L.divIcon({
                                html: `<svg class="w-4 h-4 fill-current"><use href="#icon-user"/></svg>`,
                                className: "svg-icon me",
                                iconSize: [iconSize, iconSize], // size of the icon
                                iconAnchor: [iconSize / 2, iconSize / 2], // point of the icon which will correspond to marker's location
                            });
                            this.meMarker = L.marker(new L.LatLng(coords.latitude, coords.longitude), {icon: meIkon});
                            this.meMarker.addTo(this.map);
                            this.map.panTo([coords.latitude, coords.longitude], {duration: 1})
                        } else {
                            if (typeof this.kriterier?.geo !== 'undefined') {
                                this.kriterier.geo = null;
                            }
                            this.map.removeLayer(this.meMarker);
                        }
                    })
                },
                toggleNav() {
                    if (this.disabled) {
                        return;
                    }
                    if (this.geo === null) {
                        this.laster = true;
                        navigator.geolocation.getCurrentPosition((position) => {
                            this.geo = position.coords;
                            this.laster = false;
                        }, (error) => {
                            // For testing av funksjonaliteten
                            // this.geo = {latitude: 63.4346, longitude: 10.3985};
                            console.error(error);
                            this.disabled = true;
                            this.laster = false;
                        });
                    } else {
                        this.geo = null;
                    }
                },
                toggleVisning() {
                    this.visListe = !this.visListe;
                    this.$nextTick(() => {
                        this.map.invalidateSize(false);
                        setTimeout(() => {
                            this.map.invalidateSize(false);
                        }, 200);
                        x
                    })
                },
                toggleKart() {
                    this.fullKart = !this.fullKart;
                    if (this.fullKart) {
                        this.map.scrollWheelZoom.enable();
                        modules.scrollLock.enable();
                    } else {
                        this.map.scrollWheelZoom.disable()
                        modules.scrollLock.disable();
                        this.map.setView([lat, lng], zoom);
                    }
                    this.$nextTick(() => {
                        this.map.invalidateSize(false);
                    })
                },
                toggleType() {
                    if (!this.googleLayer) {
                        // L: leaflet
                        this.googleLayer = L.tileLayer('//{s}.google.com/vt/lyrs=s@189&x={x}&y={y}&z={z}', {
                            maxZoom: 20,
                            subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
                        });
                        this.map.addLayer(this.googleLayer);
                    } else {
                        this.map.removeLayer(this.googleLayer);
                        this.googleLayer = null;
                    }
                },
                zoom(type) {
                    if (type === '-') {
                        this.map.setZoom(this.map.getZoom() - 1)
                    } else if (type === '+') {
                        this.map.setZoom(this.map.getZoom() + 1);
                    }
                },
                addMarker(lat, lng, type = 'avd') {
                    let icon = null,
                        iconSize = 0;
                    switch (type) {
                        case 'avd':
                            iconSize = 30;
                            icon = L.divIcon({
                                html: `<svg class="w-10 h-2 fill-current"><use href="#icon-logo-liten"/></svg>`,
                                className: "svg-icon avdeling",
                                iconSize: [iconSize * 2.1, iconSize], // size of the icon
                                iconAnchor: [iconSize / 1.05, iconSize / 2], // point of the icon which will correspond to marker's location
                                popupAnchor: [0, -iconSize / 1.7] // point from which the popup should open relative to the iconAnchor
                            });
                            break;
                        case 'location':
                            icon = L.divIcon({
                                html: `<svg class="w-full h-full absolute fill-current"><use href="#icon-location"/></svg>`,
                                className: "",
                                iconSize: [30, 36], // size of the icon
                                iconAnchor: [30 / 2, 36], // point of the icon which will correspond to marker's location
                            });
                            break;
                        case 'hus':
                            iconSize = 38;
                            icon = L.divIcon({
                                html: `<svg class="w-6 h-6 fill-current"><use href="#icon-home"/></svg>`,
                                className: "svg-icon p-[3px] active",
                                iconSize: [iconSize, iconSize], // size of the icon
                                iconAnchor: [iconSize / 2, iconSize / 2], // point of the icon which will correspond to marker's location
                                popupAnchor: [0, -iconSize] // point from which the popup should open relative to the iconAnchor
                            });
                            break;
                    }

                    const marker = L.marker(new L.LatLng(lat, lng), {
                        icon: icon,
                        interactive: false
                    });
                    marker.addTo(this.map);
                },
                async updateMarkers() {
                    await import('leaflet.markercluster/dist/leaflet.markercluster');
                    if (this.cluster) {
                        this.cluster.clearLayers();
                    }
                    const iconSize = 28;
                    const oppdragikon = L.divIcon({
                        html: `<svg class="w-4 h-4 fill-current"><use href="#icon-home"/></svg>`,
                        className: "svg-icon p-[3px]",
                        iconSize: [iconSize, iconSize], // size of the icon
                        iconAnchor: [iconSize / 2, iconSize / 2], // point of the icon which will correspond to marker's location
                        popupAnchor: [0, -iconSize / 1.5] // point from which the popup should open relative to the iconAnchor
                    });

                    this.cluster = L.markerClusterGroup({
                        maxClusterRadius: 20,
                        animate: false,
                        animateAddingMarkers: false,
                        chunkedLoading: true,
                        showCoverageOnHover: false,
                        removeOutsideVisibleBounds: true,
                        zoomToBoundsOnClick: true
                    });

                    this.oppdrag.forEach((item) => {
                        if (item.geo) {
                            let marker = L.marker(new L.LatLng(item.geo.lat, item.geo.lon), {icon: oppdragikon});
                            marker.bindPopup(`<a href="/${item.id}">
                                    <figure class="relative">
                                        <picture class="block w-full aspect-w-16 aspect-h-9 bg-green-200 ">
                                            <svg class="w-16 h-16 fill-green-400 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 !z-0">
                                                <use href="#icon-alt"/>
                                            </svg>
                                            <img data-src="${item.thumb}" class="object-cover lazyload ${!item.thumb && 'hidden'}"/>
                                        </picture>
                                         <span class="absolute right-2 top-2 text-green bg-green-400 p-2 text-tag ${!item.avstand && 'hidden'}">${Math.ceil(item.avstand) + ' km'} </span>
									</figure>
									<div class="text-tag text-center py-2 bg-green-200 ${!item.ledigeEnheter && 'hidden'}">${item.ledigeEnheter} ${item.ledigeEnheter > 1 ? 'ledige' : 'ledig'}</div>
									<div class="w-full px-4 py-3">
										<div class="text-tag text-gray-500 capitalize">${(item.omrade || '').toLowerCase()}</div>
										<div class="text-large">${item.overskrift}</div>
										<div class="flex text-tag gap-2 mt-2">
											<span class="bg-green-200 p-1 px-2 ${!item.type && 'hidden'}">${item.type}</span>
											<span class="bg-green-200 p-1 px-2 ${!item.soverom && 'hidden'}">${Array.isArray(item.soverom) ? item.soverom.join('-') : item.soverom} soverom</span>
											<span class="bg-green-200 p-1 px-2 ${!item.bruksareal && 'hidden'}">${Array.isArray(item.bruksareal) ? item.bruksareal.join('-') : item.bruksareal} m<sup>2</sup></span>
										</div>
										<div class="flex mt-4 justify-between items-center">
											<span class="text-header3">${Array.isArray(item.pris) ? currency(item.pris[0], false) + ' - ' + currency(item.pris[1]) : currency(item.pris)}</span>
											${item.solgt ? '<span class="p-2 bg-orange text-white text-tag uppercase">Solgt</span>' : ''}
										</div>
									</div>
								</a>`);
                            marker.on('mouseover', (e) => {
                                console.log(e.sourceTarget)
                                // e.sourceTarget._icon?.classList.toggle("active");
                            });
                            marker.on('popupopen', (e) => {
                                e.sourceTarget._icon?.classList.toggle("active");
                            });
                            marker.on('popupclose', (e) => {
                                e.sourceTarget._icon?.classList.remove("active");
                            });

                            this.cluster.addLayer(marker);
                        }
                    })

                    this.map.addLayer(this.cluster);
                },
                async hentAvdelinger() {
                    const avdelinger = (await (await fetch('/api/avdeling')).json()).data;
                    const iconSize = 30;
                    const avdelingsikon = L.divIcon({
                        html: `<svg class="w-10 h-2 fill-current"><use href="#icon-logo-liten"/></svg>`,
                        className: "svg-icon avdeling",
                        iconSize: [iconSize * 2.1, iconSize], // size of the icon
                        iconAnchor: [iconSize / 1.05, iconSize / 2], // point of the icon which will correspond to marker's location
                        popupAnchor: [0, -iconSize / 1.7] // point from which the popup should open relative to the iconAnchor
                    });

                    avdelinger.forEach((item) => {
                        if (item.geo && item.level === 1) {
                            let marker = L.marker(new L.LatLng(item.geo.lat, item.geo.lon), {icon: avdelingsikon});
                            marker.bindPopup(`<a href="${item.url}">
									<picture class="block w-full aspect-w-16 aspect-h-9 bg-green-200">
										<svg class="w-16 h-16 fill-green-400 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 !z-0">
											<use href="#icon-alt"/>
										</svg>
										<img data-src="${item.thumb}" class="object-cover lazyload ${item.thumb ? '' : 'hidden'}"/>
									</picture>
									<div class="w-full px-3 py-2">
										<div class="text-large">${item.navn}</div>
										<div class="text-small">
											<div>${item.adresse}</div>
											<div class="flex gap-2">
                                                <span>${item.postnummer}</span>
                                                <span>${item.poststed}</span>
											</div>
										</div>
									</div>
								</a>`);
                            marker.on('popupopen', (e) => {
                                e.sourceTarget._icon.classList.toggle("active");
                            });
                            marker.on('popupclose', (e) => {
                                e.sourceTarget._icon.classList.remove("active");
                            });
                            marker.addTo(this.map);
                            // this.cluster.addLayer(marker);
                        }
                    })
                }
            }
        }
    };
    Alpine.start();
});
